:root {
	--lilac: #E7E6F9;
	--rose: #F3DFDB;
	--cold-front: linear-gradient(180deg, var(--lilac) 0%, var(--rose) 100%);
	--sky-blue: #75C5E0;
	--cloud: #FFFFFF;
	--alpine-overlook: linear-gradient(180deg, var(--sky-blue) 0%, var(--cloud) 100%);
}

html {
	background: var(--alpine-overlook);
}

.mdhui-layout {
	min-height: 100vh;
	background: var(--alpine-overlook);
}