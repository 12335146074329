.badge {
	width: 50%;
	/* Centered */
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	display: block;

	/* Padding */
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
}